/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.popover___3CK4c {
  position: relative;
  width: 336px;
}
.noticeButton___2tmwZ {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.icon___1g-4R {
  padding: 4px;
  vertical-align: middle;
}
.badge___2Vc9h {
  font-size: 16px;
}
.tabs___dTkaB .ant-tabs-nav-list {
  margin: auto;
}
.tabs___dTkaB .ant-tabs-nav-scroll {
  text-align: center;
}
.tabs___dTkaB .ant-tabs-bar {
  margin-bottom: 0;
}
