/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.headerSearch___RN1il {
  display: inline-flex;
  align-items: center;
}
.headerSearch___RN1il .input___3Vzpl {
  width: 0;
  min-width: 0;
  overflow: hidden;
  background: transparent;
  border-radius: 0;
  transition: width 0.3s, margin-left 0.3s;
}
.headerSearch___RN1il .input___3Vzpl .ant-select-selection {
  background: transparent;
}
.headerSearch___RN1il .input___3Vzpl input {
  box-shadow: none !important;
}
.headerSearch___RN1il .input___3Vzpl.show___VZRKu {
  width: 210px;
  margin-left: 8px;
}
