/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu___1L63y .anticon {
  margin-right: 8px;
}
.menu___1L63y .ant-dropdown-menu-item {
  min-width: 160px;
}
.right___3L8KG {
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  overflow: hidden;
}
.right___3L8KG .action___LP4_P {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.right___3L8KG .action___LP4_P > span {
  vertical-align: middle;
}
.right___3L8KG .action___LP4_P:hover {
  background: rgba(0, 0, 0, 0.025);
}
.right___3L8KG .action___LP4_P.opened {
  background: rgba(0, 0, 0, 0.025);
}
.right___3L8KG .search___2W0sJ {
  padding: 0 12px;
}
.right___3L8KG .search___2W0sJ:hover {
  background: transparent;
}
.right___3L8KG .account___6HXOq .avatar___2cOWV {
  margin-right: 8px;
  color: #1890ff;
  vertical-align: top;
  background: rgba(255, 255, 255, 0.85);
}
.dark___1NwCY .action___LP4_P:hover {
  background: #252a3d;
}
.dark___1NwCY .action___LP4_P.opened {
  background: #252a3d;
}
@media only screen and (max-width: 768px) {
  .ant-divider-vertical {
    vertical-align: unset;
  }
  .name___WfKAK {
    display: none;
  }
  .right___3L8KG {
    position: absolute;
    top: 0;
    right: 12px;
  }
  .right___3L8KG .account___6HXOq .avatar___2cOWV {
    margin-right: 0;
  }
  .right___3L8KG .search___2W0sJ {
    display: none;
  }
}
