/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.list___1Bcfk {
  max-height: 400px;
  overflow: auto;
}
.list___1Bcfk::-webkit-scrollbar {
  display: none;
}
.list___1Bcfk .item___1UQri {
  padding-right: 24px;
  padding-left: 24px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
}
.list___1Bcfk .item___1UQri .meta___17ay5 {
  width: 100%;
}
.list___1Bcfk .item___1UQri .avatar___35uD6 {
  margin-top: 4px;
  background: #fff;
}
.list___1Bcfk .item___1UQri .iconElement___2YWai {
  font-size: 32px;
}
.list___1Bcfk .item___1UQri.read___Querz {
  opacity: 0.4;
}
.list___1Bcfk .item___1UQri:last-child {
  border-bottom: 0;
}
.list___1Bcfk .item___1UQri:hover {
  background: #e6f7ff;
}
.list___1Bcfk .item___1UQri .title___6FQVY {
  margin-bottom: 8px;
  font-weight: normal;
}
.list___1Bcfk .item___1UQri .description___2ifEu {
  font-size: 12px;
  line-height: 1.5715;
}
.list___1Bcfk .item___1UQri .datetime___IKrkR {
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.5715;
}
.list___1Bcfk .item___1UQri .extra___1_Nu2 {
  float: right;
  margin-top: -1.5px;
  margin-right: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
}
.list___1Bcfk .loadMore___12o4h {
  padding: 8px 0;
  color: #1890ff;
  text-align: center;
  cursor: pointer;
}
.list___1Bcfk .loadMore___12o4h.loadedAll___3qZ2d {
  color: rgba(0, 0, 0, 0.25);
  cursor: unset;
}
.notFound___1yAup {
  padding: 73px 0 88px;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.notFound___1yAup img {
  display: inline-block;
  height: 76px;
  margin-bottom: 16px;
}
.bottomBar___1SPVP {
  height: 46px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 46px;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  transition: all 0.3s;
}
.bottomBar___1SPVP div {
  display: inline-block;
  width: 50%;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.bottomBar___1SPVP div:only-child {
  width: 100%;
}
.bottomBar___1SPVP div:not(:only-child):last-child {
  border-left: 1px solid #f0f0f0;
}
